import axios from 'axios';
import ReportService from '@/services/report.service';

export default class ProjectReportService extends ReportService {
    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param startAt
     * @param endAt
     * @param users
     * @param projects
     */
    getReport(startAt, endAt, users, projects) {
        return axios.post('api/v1/report/project', {
            start_at: startAt,
            end_at: endAt,
            users,
            projects,
        });
    }

    /**
     * @returns {Promise<AxiosResponse<T>>}
     * @param startAt
     * @param endAt
     * @param users
     * @param projects
     * @param format
     */
    async downloadReport(startAt, endAt, users, projects, format, type) {
        const params = {
            start_at: startAt,
            end_at: endAt,
            format: format,
            type: type,
            users,
            projects,
        };

        return axios.post(`api/v1/report/project/download`, params, {
            responseType: 'arraybuffer',
            headers: { Accept: format },
        });
    }
}
