<template>
    <div class="dropdown">
        <at-dropdown :placement="position" :trigger="trigger" @on-dropdown-command="onExport">
            <at-button type="text">
                <span class="icon icon-save" />
            </at-button>

            <at-dropdown-menu slot="menu">
                <at-dropdown-item v-for="(type, key) in types" :key="key" :name="key">{{
                    key.toUpperCase()
                }}</at-dropdown-item>
            </at-dropdown-menu>
        </at-dropdown>
    </div>
</template>

<script>
    import AboutService from '@/services/resource/about.service';

    const aboutService = new AboutService();

    export default {
        name: 'ExportDropdown',
        props: {
            position: {
                type: String,
                default: 'bottom-left',
            },
            trigger: {
                type: String,
                default: 'click',
            },
        },
        data: () => ({
            types: [],
        }),
        async created() {
            this.types = await aboutService.getReportTypes();
        },
        methods: {
            onExport(format) {
                this.$emit('export', { format: this.types[format], type: format });
            },
            onClose() {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dropdown {
        display: block;
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        .theme-dark & {
            background-color: #333;
        }

        &::v-deep .at-btn__text {
            color: #000;
            font-size: 25px;

            .theme-dark & {
                color: #c4c4cf;
            }
        }
    }

    .at-btn {
        .theme-dark & {
            background-color: #1f1f1f;
            &:hover {
                border: 1px solid #c4c4cf;
                border-radius: 10%;
                background-color: #333;
            }
        }
    }

    .at-dropdown-menu {
        right: 5px;
        border-radius: 10px;
        .theme-dark & {
            background-color: #333;
        }

        &__item {
            &:hover {
                background-color: #555;
            }
        }
    }
</style>
