import axios from 'axios';

export default class AboutService {
    async getGeneralInfo() {
        return await axios.get('about');
    }

    async getStorageInfo() {
        return await axios.get('about/storage');
    }

    startCleanup() {
        return axios.delete('api/v1/about/storage');
    }

    async getReportTypes() {
        const { data } = await axios.get('api/v1/about/reports');
        return data.data.attributes.types;
    }
}
