var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"project__header"},[_c('h1',{staticClass:"project__title"},[_vm._v(_vm._s(_vm.project.attributes.project_name))]),_c('span',{staticClass:"h3"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.projectTimeByDay(_vm.project.id))))])]),_c('at-collapse',{staticClass:"list__item",attrs:{"simple":""}},_vm._l((_vm.project.attributes.users),function(user){return _c('at-collapse-item',{key:user.id,staticClass:"list__item"},[_c('div',{staticClass:"row flex-middle",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"col-3 col-xs-2 col-md-1"},[_c('user-avatar',{attrs:{"user":user.attributes,"size":_vm.avatarSize}})],1),_c('div',{staticClass:"col-8 col-md-10 col-lg-11"},[_c('span',{staticClass:"h5"},[_vm._v(_vm._s(user.attributes.full_name))])]),_c('div',{staticClass:"col-4 col-md-3 col-lg-2"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.userTimeByDay(user.id, _vm.project.id))))])]),_c('div',{staticClass:"col-10"},[_c('at-progress',{attrs:{"status":"success","stroke-width":15,"percent":_vm.getUserPercentage(
                                _vm.userTimeByDay(user.id, _vm.project.id),
                                _vm.project.attributes.project_total_time,
                            )}})],1)]),_c('at-collapse',{attrs:{"simple":"","accordion":""},on:{"on-change":function($event){return _vm.handleCollapseTask(user, $event)}}},_vm._l((_vm.filterTasks(user.id)),function(task){return _c('at-collapse-item',{key:`tasks-${task.id}`,attrs:{"name":`${task.id}`}},[_c('div',{staticClass:"row",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"col-10 col-md-11 col-lg-12"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(task.attributes.task_name))])]),_c('div',{staticClass:"col-4 col-md-3 col-lg-2"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.taskTimeByDay(task.id, user.id))))])]),_c('div',{staticClass:"col-10"},[_c('at-progress',{attrs:{"status":"success","stroke-width":15,"percent":_vm.getUserPercentage(
                                        _vm.taskTimeByDay(task.id, user.id),
                                        _vm.project.attributes.project_time,
                                    )}})],1)]),(_vm.checkAccess())?_c('at-collapse',{staticClass:"project__screenshots screenshots",attrs:{"accordion":""},on:{"on-change":_vm.handleCollapseDate}},[_c('span',{staticClass:"screenshots__title"},[_vm._v(_vm._s(_vm.$t('field.screenshots')))]),_vm._l((_vm.filterIntervals(user.id, task.id)),function(interval,key){return _c('at-collapse-item',{key:key,attrs:{"name":`${task.id}-${key}`}},[_c('div',{staticClass:"row",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"h5 screenshots__date"},[_vm._v(" "+_vm._s(`Time ${_vm.moment(interval.attributes.created_at) .locale(_vm.$i18n.locale) .format('HH:MM:SS')}`)+" ")])]),_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"h5"},[_vm._v(_vm._s(_vm.formatDurationString(interval.attributes.time)))])])]),(_vm.isDateOpened(`${task.id}-${key}`))?[[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[(interval)?_c('Screenshot',{key:interval.id,staticClass:"screenshots__item",attrs:{"interval":interval,"user":user,"task":task,"disableModal":true,"showNavigation":true,"showTask":false},on:{"click":function($event){return _vm.onShow(interval, user, task, _vm.project)}}}):_c('div',{key:_vm.index,staticClass:"screenshots__item screenshots__placeholder"})],1)])]]:_vm._e()],2)})],2):_vm._e()],1)}),1)],1)}),1),(_vm.modal.interval)?_c('ScreenshotModal',{attrs:{"show":_vm.modal.show,"interval":_vm.modal.interval,"project":_vm.project,"task":_vm.modal.task,"user":_vm.modal.user,"showNavigation":true,"canRemove":false},on:{"close":_vm.onHide,"showPrevious":_vm.onShowPrevious,"showNext":_vm.onShowNext}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }